import Vue from 'vue'

let ivSimpleChart = Vue.component('iv-simple-chart', {
    props: ['charts', 'role', 'max'],

    computed: {
        dataset: function () {
            for (let r in this.$props.charts) {
                if (this.$props.charts[r].role === this.$props.role) {
                    return this.$props.charts[r]['dataset']
                }
            }
        },

        simpleCharts: function () {
            let simpleCharts = []
            for (let s in this.dataset) {
                let value = this.dataset[s][1]
                let percent = Math.round(value / this.$props.max * 100)
                let chart = {
                    label: this.$parent.format_dollar(value),
                    barStyle: `width:${percent}%`,
                }
                simpleCharts.push(chart)
            }
            return simpleCharts
        },
    },

    template: '#iv-simple-chart',
})

export {ivSimpleChart}